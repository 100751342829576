import "vant/es/button/style";
import _Button from "vant/es/button";
export default {
  name: 'login',
  components: {
    [_Button.name]: _Button
  },
  data() {
    return {
      buttonLoad: false,
      buttonLoadtwo: false,
      formData: {
        name: '',
        workNumber: ''
      }
    };
  },
  async created() {},
  methods: {
    async loginClick() {
      if (!this.formData.name) {
        this.$toast({
          message: '请输入姓名'
        });
        return false;
      }
      if (!this.formData.workNumber) {
        this.$toast({
          message: '请输入工号'
        });
        return false;
      }
      this.buttonLoadtwo = true;
      let result = await this.$request({
        method: 'post',
        url: '/app/auth/info',
        data: {
          name: this.formData.name,
          workNumber: this.formData.workNumber
        }
      });
      console.log(result);
      this.buttonLoadtwo = false;
      if (result.code == 0) {
        this.$toast({
          message: '认证成功'
        });
        this.$store.commit('setUserInfo', result.data);
        this.$router.back();
      } else {
        this.$toast({
          message: result.message
        });
      }
    }
  }
};