var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "content-box"
  }, [_c('div', {
    staticClass: "logo"
  }), _c('div', {
    staticClass: "logo-label"
  }, [_vm._v("企业认证")]), _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "item"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-11.png"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.name,
      expression: "formData.name"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "姓名"
    },
    domProps: {
      "value": _vm.formData.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "item"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": "https://qiniu.touchdot.top/code-cdn/hongrun-gifttwo/icon/icon-10.png"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.workNumber,
      expression: "formData.workNumber"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "placeholder": "工号"
    },
    domProps: {
      "value": _vm.formData.workNumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "workNumber", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "sub-button"
  }, [_c('van-button', {
    attrs: {
      "color": "#C70C0C",
      "loading": _vm.buttonLoadtwo,
      "loading-size": "24px",
      "loading-text": "加载中...",
      "size": "large",
      "block": ""
    },
    on: {
      "click": _vm.loginClick
    }
  }, [_vm._v("认证 ")])], 1)])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };